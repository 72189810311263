import {Sidebar} from "../../PopUps/Sidebar";
import {setter} from "elum-state/react";
import { SIDEBARS, } from "../../../../state/elum";
import styles from "./index.module.scss";
import {BalanceDiamond} from "../../../../../Assets/icons";
import clsx from "clsx";
import React from "react";
import {useTonAddress, useTonConnectModal, useTonConnectUI} from "@tonconnect/ui-react";
import {game} from "../../../../Api";

export const SpecialTask2 = () => {

    const onClose = async () => {
        setTimeout(() =>
                setter(SIDEBARS, '')
            , 500)
    };

    const className = {
        wrapper: styles.wrapper,
        header: styles.header
    }

    const userFriendlyAddress = useTonAddress();
    const { state, open: openTon, close } = useTonConnectModal();
    const [tonConnectUI, setOptions] = useTonConnectUI();

    const make = async () => {

        if (userFriendlyAddress === '') {
            onClose()
            return openTon()
        }

        const paymentParams = {
            validUntil: Math.floor(Date.now() / 1000) + 3600,
            messages: [
                {
                    address: 'UQBjOUa4SdREpSkSf86EmYl7q-QCotM0DcfoZb_ZP54koMws',
                    amount: '500000000',
                },
            ],
        };

        try {
            const data = await tonConnectUI.sendTransaction(paymentParams);
            if (data.boc) {
                onClose()
                return game.transaction.make(data)
            }
        } catch (e) {
            console.log(e)
        }


    }

    return (
        <Sidebar
            onClose={onClose}
            custom={{bottom: 0, zIndex: 999}}
            className={className}
        >

            <img className={styles.img} src={'./assets/misc/special_task_bg.jpg'}/>
            <div className={styles.sidebar}>
                <div>
                    <img src={'./assets/tasks/special_task.png'}/>
                </div>

                <div className={styles.sidebar_text}>
                    <p>New special task</p>
                    <span>
                        <p>+ 100.000 m. and 90</p><BalanceDiamond/>
                    </span>
                </div>

                <div className={styles.sidebar_btn}>
                    <button className={clsx(['btn_primary'])} onClick={make}>Got it!</button>
                </div>
            </div>
        </Sidebar>
    )
}