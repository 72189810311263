import React from 'react';
import ReactDOM from 'react-dom/client';
import './styles/global.scss'
import App from './App';
import {SpotAdsProvider} from "spot-ads-react";


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);


export const {Telegram} = window
export const {show_8542901} = window

if (Telegram.WebApp.platform === 'unknown' && false) {
    root.render(<></>)
} else {

    let headerColor = '#040215'

    if (Telegram.WebApp.colorScheme === 'dark') {
        headerColor =  '#040215'
    }

    Telegram.WebApp.setHeaderColor(headerColor)

    root.render(
        <SpotAdsProvider apiKey="spot-Njc2MmI4NzE2MDc2ZjNjNjhmYTE5YTI4">
            <App />
        </SpotAdsProvider>

    );
}



